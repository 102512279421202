<template>
  <Teleport to="body">
    <Transition name="fade" appear>
      <div v-if="show" class="fixed inset-0 z-50 bg-black opacity-50"></div>
    </Transition>
    <Transition appear :name="'dialog'">
      <div v-if="show" class="fixed inset-0 z-50 flex overflow-auto">
        <slot />
        <Transition name="fade" appear>
          <button
            v-if="showBack"
            class="absolute left-0 top-0 z-[1] p-4 md:left-8 md:top-5"
            type="button"
            @click="onClick()"
          >
            <svg
              width="17"
              height="25"
              viewBox="0 0 17 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M2.74935 12.6424L2.56903 12.4901L2.74935 12.3378L15.8947 1.23722L14.7905 0.304773L0.360622 12.4901L14.7905 24.6754L15.8947 23.743L2.74935 12.6424Z"
                fill="white"
                stroke="white"
                stroke-width="0.472018"
              />
            </svg>
          </button>
        </Transition>
        <BackHomeV2
          v-if="showClose"
          :navigate="false"
          class="absolute right-4 top-4 z-[1] md:right-6 md:top-6"
          @click="onClick()"
        />
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
export interface ModalOnCloseEvent {
  outside: boolean
}

export type ModalVariant = 'basic' | 'fullscreen'

interface ModalProps {
  show?: boolean
  showBack?: boolean
  showClose?: boolean
  variant?: ModalVariant
  contentClass?: string
}

const props = withDefaults(defineProps<ModalProps>(), {
  showBack: false,
  showClose: true,
  variant: 'basic'
})

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits<{
  'on-close': [event: ModalOnCloseEvent]
}>()

const modalRef = ref<HTMLElement | null>(null)

const { bodyLock, bodyUnlock } = useBodyLock()

onClickOutside(modalRef, () => {
  emit('on-close', { outside: true })
})

function onClick() {
  emit('on-close', { outside: false })
}

watch(
  () => props.show,
  (isShow) => {
    if (isShow) {
      bodyLock()
    } else {
      bodyUnlock()
    }
  }
)

onUnmounted(() => {
  bodyUnlock()
})
</script>
